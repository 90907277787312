<template>
  <v-container>
    <v-row>
      <v-col>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Question</th>
                <th class="text-left">Response</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in projectSpecifics" :key="item.Action">
                <td>{{ item.Question }}</td>
                <td>{{ simplifyResponse(item.Response) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "App",
  props: {},
  components: {},

  data: () => ({
    questions: {
      EIRSupplied:
        "Has the client provided an Employer Information Requirements (EIR) ?",
      EIRSuppliedTrue: "Link to EIR",
      EIRSuppliedFalse:
        "EIR is an essential document for the successful implementation of a project. If appropriate we recommend PM to contact client to discuss Arup providing this scope on employer's behalf, but at the very least we should have a copy of this document.",
      BEPSupplied: "Is there a BIM execution plan for the project? (BEP)",
      BEPSuppliedTrueA: "Please add a link",
      BEPSuppliedTrueB: "Is the BEP ISO 19650 compliant ?",
      BEPSuppliedFalseA:
        "Buildings and Infrastructure projects should have BIM execution plans. A BIM execution plan should be developed.",
      BEPSuppliedFalseB: "Assign an action item for this",
      LODAgreements:
        "Are there agreed Levels of Development/Detail for each project stage?",
      LODAgreementsFalse: "Assign an action item for this",
      OpenBIMApproach:
        "Are there opportunities for an OpenBIM approach? eg. IFC approach",
      OpenBIMApproachTrue: "Add information",
      OpenBIMApproachFalse: "Assign an action item for this",
      BIMmmReview:
        "Have you reviewed the BIM Maturity Measure to inform this review?",
      BIMmmReviewTrue: "Add the link to the completed BIM Maturity Measure",
      BIMmmReviewFalse: "Assign an action item for this",
      GISRequirements: "Has the client provided GIS / Geospatial requirements?",
      GISRequirementsTrueA: "Please add a link",
      GISRequirementsTrueB:
        "Have you reviewed and understood the GIS standards required by client?",
      GISRequirementsTrueBTrue: "Select Type",
      GISRequirementsTrueBFalse: "Assign an action to agree",
      GISRequirementsFalse:
        "Assign a team member to speak to the client to scope out requirements",
    },
  }),
  mounted() {},

  methods: {
    simplifyResponse(val) {
      if (val == true) {
        val = "Yes";
      }
      if (val == false) {
        val = "No";
      }
      return val;
    },
  },

  computed: {
    projectSpecifics() {
      let result = [];

      for (const [key, value] of Object.entries(this.questions)) {
        if (this.$store.state.plan.DigitalPlan[key] != null) {
          if (typeof this.$store.state.plan.DigitalPlan[key] !== "object") {
            result.push({
              Question: value,
              Response: this.$store.state.plan.DigitalPlan[key],
            });
          } else {
            if (this.$store.state.plan.DigitalPlan[key].Name != null) {
              result.push({
                Question: value,
                Response: this.$store.state.plan.DigitalPlan[key].Name,
              });
            }
          }
        }
      }

      return result;
    },
  },
};
</script>
