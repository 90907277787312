<template>
  <v-container>
    <v-row>
      <v-col>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Symptoms</th>
                <th class="text-left">Automation</th>
                <th class="text-left">Coordination</th>
                <th class="text-left">Pain score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in painPoints" :key="item.Id">
                <td>{{ item.Name }}</td>
                <td>{{ item.Symptoms }}</td>
                <td>{{ Math.abs(item.AutomationSlider) }}</td>
                <td>{{ Math.abs(item.CoordinationSlider) }}</td>
                <td>
                  {{
                    getPainScore(item.AutomationSlider, item.CoordinationSlider)
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "App",
  props: {},
  components: {},

  data: () => ({}),
  mounted() {},

  computed: {
    painPoints() {
      return this.$store.state.plan.DigitalPlan.PainPoints;
    },
  },
  methods: {
    getPainScore(AutomationSlider, CoordinationSlider) {
      return Math.abs(AutomationSlider * CoordinationSlider);
    },
  },
};
</script>
