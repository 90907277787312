<template>
  <v-container>
    <v-row>
      <v-col> Project Director: {{ ProjectDirector.Name }} </v-col>
      <v-col> Digital Lead: {{ DigitalLead.Name }} </v-col>
    </v-row>
    <v-row>
      <v-col> Project Manager: {{ ProjectManager.Name }} </v-col>
      <v-col> Digital Facilitator: {{ DigitalFacilitator.Name }} </v-col>
    </v-row>

    <v-row>
      <v-col>
        Discipline Leads:
        <div v-for="item in DisciplineLeads" v-bind:key="item.Id">
          {{ item.Lead.Name }} - {{ item.Discipline }}
        </div>
      </v-col>
      <v-col>
        Other Attendees:
        <div v-for="item in OtherAttendees" v-bind:key="item.Email">
          {{ item.Name }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({}),
  computed: {
    ProjectDirector() {
      return this.$store.state.plan.ProjectDirector;
    },
    ProjectManager() {
      return this.$store.state.plan.ProjectManager;
    },
    DigitalFacilitator() {
      return this.$store.state.plan.DigitalPlan.DigitalFacilitator;
    },
    DigitalLead() {
      return this.$store.state.plan.DigitalPlan.DigitalLead;
    },
    OtherAttendees() {
      return this.$store.state.plan.DigitalPlan.OtherAttendees;
    },
    DisciplineLeads() {
      return this.$store.state.plan.DigitalPlan.DisciplineLeads;
    },
  },

  methods: {},
};
</script>

<style >
.routerLink {
  text-decoration: none;
}
</style>
