<template>
  <v-container>
    <v-row>
      <v-col>
        <p>
          It is critical that the contract has been fully checked by a senior
          knowledgeable leader for digital legal issues and potential
          restrictions, for example Intellectual Property, data residency
          limitations or restrictions on reuse of data. <br />
          By ticking Yes you are stating that these issues are known and
          understood by the Project Director that the issues will be explored
          during the Digital Inception process.
        </p>
      </v-col>

      <v-col>
        {{ simplifyResponse(contractClause) }}
      </v-col>
      <!-- <v-btn @click="debug"> debug </v-btn> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "App",
  props: {},
  components: {},

  data: () => ({
    ClientAimsHeaders: [
      { text: "Aim", value: "Aim", sortable: true },
      { text: "", value: "Edit", sortable: false },
    ],
  }),
  mounted() {},

  computed: {
    contractClause() {
      return this.$store.state.plan.DigitalPlan.Contract;
    },
  },
  methods: {
    simplifyResponse(val) {
      if (val) {
        val = "Yes";
      } else if (!val) {
        val = "No";
      }
      return val;
    },
    debug() {
      console.log(this.contractClause);
    },
  },
};
</script>


